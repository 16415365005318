import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.min.js";
import { Container } from "react-bootstrap";
import Header from "../Header";
import Footer from "../Footer";
import SignUpBanner from "./SignUpBanner";
import SignUpForm from "./SignUpForm";
function Register() {
  return (
    <>
      <Header />
      <section>
        <Container fluid>
          <SignUpBanner />
        </Container>
      </section>
      <section>
        <Container fluid>
          <SignUpForm />
        </Container>
      </section>
      <Footer />
    </>
  );
}
export default Register;
