import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import Header from "./Header.js";
import reportWebVitals from "./reportWebVitals";
import Footer from "./Footer";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import Home from "./Homepage";
import About from "./Aboutpage";
import Price from "./Pricing";
import Features from "./Features";
import ContactUs from "./Contact-Us";
import PrivacyPolicy from "./Privacy-Policy";
import SignUp from "./Register/SignUp.js";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <>
    <React.StrictMode>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Home />}>
            home
          </Route>
          <Route path="*" element={<Navigate to="/" />} />
          <Route path="/about" element={<About />}>
            about
          </Route>
          {/* <Route path="beta/careers" element={<Careers/>}>
          Careers
        </Route> */}
          <Route path="/pricing" element={<Price />}>
            pricing
          </Route>
          <Route path="/features" element={<Features />}>
            features
          </Route>
          <Route path="/contact-Us" element={<ContactUs />}>
            Contact-Us
          </Route>
          <Route path="/privacy-policy" element={<PrivacyPolicy />}>
            privacypolicy
          </Route>
          <Route path="/register" element={<SignUp />}>
            SignUp
          </Route>
        </Routes>
      </BrowserRouter>
    </React.StrictMode>
  </>
);
reportWebVitals();
