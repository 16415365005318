import React from "react";
import { Link } from "react-router-dom";

const Getdemo = () => {
  return (
    <div>
      <button className="btn sm demo-btn">
        <Link to="/register">Sign up now</Link>
      </button>
    </div>
  );
};

export default Getdemo;
