import React from "react";
import { Link } from "react-router-dom";
function Getfreedemo() {
  return (
    <>
      <Link to="/register" className="sign-up-anchor">
        <button className="btn md free-demo-btn">Sign up now</button>
      </Link>
    </>
  );
}
export default Getfreedemo;
