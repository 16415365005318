import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.min.js';
function PrivacyPolicy(){
    return(
        <>
        <div class="layout-wrapper layout-navbar-full layout-horizontal layout-without-menu">
    <div class="layout-container">
      <div class="layout-page">
        
        <div class="content-wrapper">
            <div class="col-md-12 mt-4">
    <div class="container-fluid ">
      <div class="row bg-white pt-4 pb-5 b-radius ">
       
        <div class="col-12 mb-4 ">
          <div class="container bs-stepper wizard-numbered mt-5 col-sm-8">
          <h2 class="pb-4  ">Privacy policy</h2>
            <div class="border-bottom pb-3">
              <p> We, at <strong> Eazio</strong> FZC Corporation and its affiliates (collectively referred to as <strong>“Eazio”</strong>, <strong>“company”</strong>, <strong>“Eazio”</strong>, <strong>“we”</strong> or <strong>“us”</strong>) develop and operate an HR management platform (the “<strong>platform</strong>“) that helps companies streamline core HR processes (the “<strong>Customer</strong>” or “<strong>your organization</strong>”).</p>
              <p>This Privacy Policy describes our approach to the collection, storage, usage, and disclosure of data that relates to identifiable individuals who visit or otherwise interact with our website (<strong>“Visitors”</strong>) available at <strong>www.eazio.com</strong>, or any other webpage, email, or online ad under our control (collectively, “Sites”), as well as with respect to our Customers’ employees, contractors, freelancers and/or any individuals (<strong>“End-User”</strong>, <strong>“data subject”</strong>, <strong>“you”</strong> or <strong>“your” </strong>) using the platform on behalf of the Customer.</p>
              <p>This privacy policy addresses our privacy practices with respect to our Sites and the platform. With regards to your personal data you share with us via our Sites, we remain the data controller. However, please note that your organization is the <strong>“data controller”</strong> of your personal data we process on the platform, and Eazio is the <strong>“data processor”</strong> acting on behalf of your organization. In other words, your personal data is provided to us in the framework of our relationship with your organization and we are not responsible for your organization’s privacy practices.</p>
              <p>The responsibility for establishing the appropriate legal basis and complying with any laws and regulations applicable to a data controller with respect to your personal data, lies with your organization which may have additional privacy notices explaining its own specific privacy practices, in which case, we encourage you to read them.</p>
              <p>We respect your privacy and are strongly committed to making our practices regarding your personal data transparent and fair. <strong>Please read this privacy policy carefully and make sure that you fully understand and agree to it.</strong></p>
              <p>You are not legally required to provide us with any personal data, but without it, we may not be able to provide you with the full range of our services or with the best user experience when interacting with our Sites and the platform.</p>
            </div>
            <div class="pt-3">
              <h3>1. Data collection</h3>
              <p>We collect various types of personal data regarding our Visitors (<strong>“Visitors data”</strong>) and End-Users. Such information is typically collected or generated through your interaction with our sites and the platform or collected directly from you or your organization.</p>
              <p>Specifically, we may process the following categories of your personal data:</p>
              <li class="mb-3"><strong>End-User data received from you</strong>: When you sign up to the platform and create your individual profile (<strong>“user profile”</strong>), you provide us with personal data. This may include your name, age, gender, religion, role, salary information, contact details (such as email, phone number and address), account login details, image, ID (such as national ID, passport), medical insurance or health information, as well as any other data your organization may deem necessary for the use of the platform. Once you are logged in to the platform, your organization or other End-Users in your organization may submit additional details and documentation about you, depending on the requirements set by your organization. This could include your government-issued ID, information and documentation concerning your employment, compensation and benefits, family status and details on your dependents and emergency contacts, bank account details, investment preferences and plans, and other information you or your organization choose to submit in order to further and more fully utilize the different features of the platform (<strong>“End-User data”</strong>).</li>
              <li class="mb-3"><strong>Administrative data received from you</strong>: When you are registered by us as an admin of a Customer’s account (“Admin”), or as a Visitor to the Site, you may provide us with personal data relating to you. This typically includes your name, workplace, position, and contact information (such a professional email and phone number). During your interaction with our platform as an Admin, you may provide us with personal data relating to your organization’s End-Users (e.g., contact details of your organization’s employees in order to sign them up to the platform). Please note that in such instances, the Customer on behalf of which you are an Admin will be solely responsible for determining whether to provide us with said data, as well as for establishing an appropriate legal basis for such processing activities as required under applicable law.</li>
              <li class="mb-3"><strong>Data automatically collected or generated</strong>: When you visit or interact with our Sites we may collect, record, or generate certain technical data about you through the use of <strong>“cookies”</strong> and other tracking technologies (in the manner further detailed in Section 5 below). Such data usually consists of connectivity technical and aggregated usage data, such as IP addresses and general locations, device and application data (such as type of operating system, mobile device ID, browser version, locale, and language settings used), date and time stamps of usage, the cookies and pixels installed or utilized on such device and your recorded activity (sessions, clicks, and other interactions) in connection with our Sites and platform. In addition, phone calls (e.g., with our sales representatives and customer success, etc.) may be automatically recorded, tracked, and analyzed, for purposes such as analytics, service, business quality control and improvements, and record-keeping purposes.</li>
              <li class="mb-3"><strong>Data concerning service integrations</strong>: If, when using the platform, you or your Admin choose to integrate your organization’s account with a third-party service (and such service is supported by our platform), we will connect and integrate that third-party service to the platform. The third-party provider of this integration may receive certain relevant data about or from your organization’s account (including End-User Data) or share certain relevant data from your account on their service with our platform, depending on the nature and purpose of such integration. It is the responsibility of your organization to ensure that the privacy practices of such integration meet your organizations’ privacy standards.</li>
              <li class="mb-3"><strong>Data received from other third parties</strong>: We may receive personal data concerning you from other sources. For example, if you participate in an event, webinar, or promotion that we sponsor or participate in, we may receive your personal data from its organizers. We may also receive your contact and professional details (e.g., your name, company, position, contact details and professional experience, preferences and interests) from our business partners or service providers, and through the use of tools and channels commonly used for connecting between companies and individuals in order to explore potential business and employment opportunities, such as LinkedIn and other similar platforms.</li>
              <li class="mb-3"><strong>Data obtained through analytics tools</strong>: We use analytics tools (e.g., Google analytic to collect data about the use of our Sites. Analytics tools collect information such as how often Visitors visit the Sites, which pages they visit and when, and which website, ad, or email message brought them there.</li>
              <h3>2. Data uses</h3>
              <p>In general terms, your organization may use our platform to process your persona data in order to better manage its human resources and employee benefits, to track workflows and individual performance, and to cultivate interpersonal relationships within the organization.</p>
              <p>Eazio may process your End-User Data and Visitor Data as is necessary for the performance of our services and to facilitate, operate, and maintain the Sites and the platform (all in accordance with the instructions provided to us by your organization (in their role as data controller); to comply with our legal and contractual obligations; providing customer service and technical support; and protecting and securing our End-Users, Customers, ourselves and our platform.</p>
              <h3>3. Data location and retention</h3>
              <p><strong>Data location</strong>: Your personal data is maintained, processed and stored by us and our authorized service providers (defined below) in Bahrain and the United arab emirates (UAE). We may also retain your personal data in other locations as reasonably necessary for the proper performance and delivery of our services or as may be required by applicable law. Your personal data may be accessed from any of those locations (or other locations as reasonably necessary for the platform’s activity) by Eazio employees tasked with handling your organization’s data. Such access usually occurs in the course of providing your organization with customer support, technical assistance, etc.</p>
              <p>While privacy laws may vary between jurisdictions, Eazio and its affiliates and service providers are each committed to protecting personal data in accordance with this privacy policy and customary industry standards, and such appropriate lawful mechanisms and contractual term requiring adequate data protection. For data transfers from the UAE, we ensure that we have in place contractual protections.</p>
              <p><strong>Data retention</strong>: We will retain your personal data for as long as it is reasonably necessary in order to establish, maintain and expand our relationship and provide you and your organization with our services and offerings; in order to comply with our legal and contractual obligations; or to protect ourselves from any potential disputes (i.e. as required by laws applicable to log-keeping, records and bookkeeping, and in order to have proof and evidence concerning our relationship, should any legal issues arise following your discontinuance of use), all in accordance with our data retention policy and applicable laws.</p>
              <p>Please note that except as required by applicable law or our specific agreement with you, we will not be obligated to retain your personal data for any particular period, and we are free to securely delete, anonymize or restrict access to it for any reason and at any time, with or without notice to you.</p>
              <h3>4. Data sharing</h3>
              <p><strong>Legal compliance</strong>: In exceptional circumstances, we may disclose or allow government and law enforcement official access to your personal data, in response to a subpoena, search warrant, or court order (or similar requirement), or in compliance with applicable laws and regulations. Such disclosure or access may occur if we believe in good faith that: (i) we are legally compelled to do so; (ii) disclosure is appropriate in connection with efforts to investigate, prevent, or take action regarding actual or suspected illegal activity, fraud, or other wrongdoing; or (iii) such disclosure is required to protect the security or integrity of our End-Users, Customers, Visitors, or ourselves.</p>
              <p><strong>Service providers</strong>: We may engage selected third-party companies and individuals to perform services complementary to our own. Such service providers include hosting and server co-location services, communications, and content delivery networks (CDNs), data and cyber security services, billing and payment processing services, fraud detection and prevention services, web analytics, email distribution, marketing and monitoring services, session or active recording services, remote access service performance measurement, data optimization and enrichment services, social and advertising networks, content providers, email, voice-mails, support and customer relations management systems, and our legal, compliance and financial advisors (collectively, “<strong>service providers</strong>”).</p>
              <p>These service providers may have access to your personal data, depending on each of their specific roles and purposes in facilitating and enhancing our service, Sites and platform, and may only use it for such limited purposes as determined in our agreements with them.  When our Service Providers contact you in order to offer or promote our service, they may additionally interest you in their own services. Should you decide to engage in such activities with Eazio’s Service Providers, please note that such engagement is beyond the scope of this Privacy Policy and will therefore be covered by our Service Provider’s terms and privacy policy.</p>
              <p>In the context of this Privacy Policy, i.e., with regard to Eazio’s processing of personal data relating to Visitors and Admins, our Service Providers shall be deemed as “data processors” (as further described in Section 9 below).</p>
              <p><strong>Sharing your feedback or recommendations</strong>: If you submit a public review or feedback, we may (at our discretion) store and present your review to other Visitors of our Sites (including other Customers), or to other End-Users (as applicable). </p>
              <p><strong>Subsidiaries and affiliated companies</strong>: We may share personal data internally within our group, for the purposes described in this Privacy Policy.  In addition, should Eazio or any of its affiliates undergo any change in control or ownership, including by means of merger, acquisition or purchase of any of its assets, your personal data may be shared with the parties involved in such an event.  If we believe that such change in control might materially affect your personal data then stored with us, we will notify you of this event and the choices you may have via email or prominent notice on our Sites.</p>
              <p>For the avoidance of doubt, Eazio may share your personal data in additional manners, pursuant to your explicit approval, or if we are legally obligated to do so, or if we have successfully rendered such data non-personal and anonymous. We may transfer, share or otherwise use non-personal data at our sole discretion and without the need for further approval.</p>
              <h3>5. Cookies and tracking technologies</h3>
              <p>Our Sites, platform and our services (including some of our Service Providers) utilize “cookies”, anonymous identifiers, pixels, container tags and other technologies in order for to provide our service and ensure that it performs properly, to analyze our performance and marketing activities, and to personalize your experience. For more information, such cookies and similar file or tags may also be temporarily placed on your device. Certain cookies and other technologies serve to recall personal data such as an IP address, previously indicated by an End-User.</p>
              <h3>6. Communications</h3>
              <p><strong>Service communications</strong>: We may contact you with important information regarding our service. For example, we may send you notifications (through any of the means available to us) of changes or updates to our Sites, platform and service, billing issues, service change log-in attempts or password reset notice etc. Our Customers, and other End-Users on the same account may also send you notifications, messages and other update regarding their or your use of the platform.  You can control your communications and notifications settings from your End-User settings. However, please note that you will not be able to opt-out of receiving certain service communications which are integrated to your use (like password resets or billing notices). </p>
              <p><strong>Promotional communications</strong>: We may also notify you about new features, additional offerings, events and special opportunities or any other information that we think our End-Users will find valuable. We may provide such notices through any of the contact means available to us (e.g. phone number, e-mail etc.), through the Service, or through our marketing campaigns on any other sites or platforms.</p>
              <p>If you do not wish to receive such promotional communications, you may notify Eazio at any time by sending an email to hello@eazio.com, changing your communications preferences in you User profile settings, or by following the “unsubscribe”, “stop”, “opt-out” or “change email preferences” instructions contained in the promotional communications you receive.</p>
              <h3>7. Data security</h3>
              <p>In order to protect your personal data that you share with us, we are using industry-standard physical, procedural and technical security measures, including encryption as appropriate. However, please be aware that regardless of any security measures used we cannot and do not guarantee the absolute protection and security of any personal data stored with us or with any third parties as described in Section 4 above. </p>
              <h3>8. Data subject rights</h3>
              <p>If you wish to exercise your rights under laws applicable to you, such as the right to request access to, and rectification or erasure of your personal data held with Eazio, and the right to object at any time to processing personal data concerning you which is based on our legitimate interests, as detailed in Section 2 above (each to the extent available to you under the laws which apply to you) – please contact us at: legal@eazio.com.  Please note that once you contact us, we may require additional information and documents, including certain person data, in order to authenticate and validate your identity and to process your request Such additional data will be then retained by us for legal purposes (e.g., as proof of the identity of the person submitting the request).</p>
              <h3>9. data controller/processor</h3>
              <p>Certain data protection laws and regulations, typically distinguish between two main roles for parties processing personal data; the “data controller”, who determines the purposes and means of processing; and the “data processor”, who processes the data on behalf of the data controller. Below we explain how these roles apply to our platform, to the extent that such laws and regulations apply.</p>
              <p>Eazio is the “<strong>data controller</strong>” of personal data relating to its Visitors and Admins. With respect to such data, we assume the responsibilities of data controller (solely the extent applicable under the law), as set forth in this Privacy Policy. In such instances, our Service Providers processing such data will assume the role of “<strong>data processor</strong>”.</p>
              <p>Your organization is the data controller of the personal data uploaded or submitted to the platform. Eazio processes such data as the data processor on behalf of your organization, in accordance with its reasonable instructions and subject to our Terms of Use and any other commercial agreements we may have with your organization. Your organization is responsible for meeting any legal requirements applicable to data controllers. If you would like to make any requests or queries regarding our processing of your personal data on behalf of your organization please contact your Admin.</p>
              <h3>10. Additional notices</h3>
              <p><strong>Updates and amendments</strong>: We may update and amend this Privacy Policy from time to time by posting an amended version on our website. The amended version will be effective as of the date it is published. When we make material changes to this Privacy Policy which in our sole discretion may affect your personal data we’ll provide you with notice as appropriate under the circumstances. Your continued use of the Sites and platform after the changes have been implemented will constitute your acceptance of the change.</p>
              <p><strong>External links</strong>: While our Sites and platform may contain links to other websites or services, we are not responsible for their privacy practices. We encourage you to pay attention when you leave our Sites and platform for the website or application of such third parties, and to read the privacy policies of each and every website and service you visit. This privacy policy applies only to our sites and platform.</p>
              <p>Our services are not designed to attract children who are minors.  We do not knowingly collect personal data from children and do not wish to do so. If we learn that a person who is to be considered as a minor according to the law applicable to him or her is using the Sites and/or platform, we will attempt to prohibit and block such use and will make efforts to promptly delete any personal data stored with us with regard such minor. If you believe that we might have any such data, please contact us by mail at hello@eazio.com.</p>
              <p><strong>Questions, concerns or complaints</strong>: If you have any comments or questions regarding this privacy policy, contact us at: hello@eazio.com.</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
          

        
        </div>
       
      </div>

    
    </div>
  </div>
        </>
    )
}
export default PrivacyPolicy;