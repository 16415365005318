import ContactBanner from "./Contact/ContactBanner";
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.min.js';
import { Container } from "react-bootstrap";
import Contactfrom from "./Contact/ContactForm";
import Header from './Header';
import Footer from './Footer';
function GetConnect(){
    return (
        <>
        <Header />
        <section>
            <Container fluid>
                <ContactBanner />
            </Container>
        </section>
        <section>
            <Container fluid>
                <Contactfrom/>
            </Container>
        </section>
        <Footer/>
        </>

    );
}
export default GetConnect;